<template>
  <el-card>
    <el-row :gutter="20" class="header">
      <!--      <el-switch-->
      <!--        v-model="value2"-->
      <!--        active-color="#13ce66"-->
      <!--        inactive-color="#ff4949">-->
      <!--      </el-switch>-->
      <el-col :span="7">
        <el-input placeholder="请输入转诊单名称..." clearable v-model="queryForm.query"></el-input>
      </el-col>
      <el-button type="primary" :icon="Search" @click="initProductList">搜索</el-button>
      <!--      <el-button type="primary" @click="handleDialogValue()">添加转诊单</el-button>-->
    </el-row>
    <el-table :data="tableData" height="550" stripe style="width: 100%">

      <!--      <el-table-column prop="num" label="编号" width="150" fixed/>-->

      <el-table-column prop="name" label="病人姓名" width="150" fixed/>

      <el-table-column prop="sex" label="病人性别" width="100" />

      <el-table-column prop="idNumber" label="病人身份证号码" width="200" />

      <el-table-column prop="telephone" label="病人手机号码" width="150" />

      <el-table-column prop="intent" label="转诊目的" width="100" />

      <el-table-column prop="departName" label="预约挂号科室" width="350" />
      <!--      range_department[preDepartmentId-20]-->

      <!--      <el-table-column prop="remark" label="是否审核" width="100" >-->
      <!--        <template v-slot="scope">-->
      <!--          <el-switch v-model="scope.row.remark"   ></el-switch>-->
      <!--        </template>-->
      <!--      </el-table-column>-->

      <el-table-column prop="examine" label="是否审核" width="100" >
        <template v-slot="scope">
          <el-tag size="default" :type="scope.row.examine_type">{{ scope.row.examine }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column prop="endDepartment" label="终审科室" width="350" />

      <el-table-column prop="arriveTime" label="预计到达时间" width="200" />

      <!--      <el-table-column prop="integral" label="申请积分" width="100" />-->
<!--      <el-table-column prop="text_isArrive" label="是否入院" width="100" />-->
      <el-table-column prop="text_isArrive" label="是否入院" width="100" >
        <template v-slot="scope">
          <el-tag size="default" :type="scope.row.type_isArrive">{{ scope.row.text_isArrive }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column prop="goodsPic" label="病人相关资料" width="150"  >
        <template v-slot="scope">
          <el-image
            style="width: 40px; height: 40px"
            :src="'https://tcm.volans.top/'+scope.row.imageUrl"
            :preview-src-list="['https://tcm.volans.top/'+scope.row.imageUrl]"
            fit="cover"
            append-to-body
          >
          </el-image>
          <!--          <img :src="'http://47.106.246.14:8081'+scope.row.imageUrl" :preview-src-list="srcList" width="45" height="45"/>-->
        </template>
      </el-table-column>



      <!--      <el-table-column prop="type" :formatter="typeFormatter" label="商品类别" width="200"/>-->




      <!--      <el-table-column prop="swiper" label="首页幻灯？" width="100" align="center">-->
      <!--        <template v-slot="{row}">-->
      <!--          <el-switch v-model="row.swiper" @change="hotSwiperChangeHandle(row)"/>-->
      <!--        </template>-->
      <!--      </el-table-column>-->

      <!--      <el-table-column prop="swiperPic" label="幻灯图片" width="200" align="center">-->
      <!--        <template v-slot="{row}">-->
      <!--          <img :src="getServerUrl()+'/image/swiper/'+row.swiperPic" width="150" height="75" />-->
      <!--        </template>-->
      <!--      </el-table-column>-->

      <!--      <el-table-column prop="swiperSort" label="幻灯排序" width="100" align="center"/>-->

      <el-table-column prop="patientDisease" label="病人病情描述" width="300" />

      <el-table-column prop="doctorName" label="上传信息人" width="300" />

      <el-table-column prop="createTime" label="上传时间" width="300" />

      <el-table-column prop="action" label="操作" width="200" fixed="right">
        <template v-slot="scope">
          <!--          <el-button type="success" @click="handleChangeImage(scope.row)">发放积分</el-button>-->
          <!--          <el-button type="primary" @click="handleChangeSwiper(scope.row)">幻灯设置</el-button>-->
          <el-button type="primary" :icon="Edit" @click="handleDialogValue(scope.row)">编辑</el-button>
          <el-button type="danger" :icon="Delete" @click="handleDelete(scope.row.reportId)">删除</el-button>
          <!--          <el-button type="primary" @click="handleChangeProductSwiperImage(scope.row)">轮播图片设置</el-button>-->
        </template>

      </el-table-column>

    </el-table>

    <el-pagination
      v-model:currentPage="queryForm.pageNum"
      :page-sizes="[10, 20, 30, 40,50]"
      :page-size="queryForm.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </el-card>

  <Dialog v-model="dialogVisible" :dialogTitle="dialogTitle" @initProductList="initProductList"  :dialogValue="dialogValue"/>
  <ImageDialog v-model="imageDialogVisible" :id="id" @initProductList="initProductList"/>
  <SwiperDialog v-model="swiperDialogVisible" :id="id" :swiperSort="swiperSort" @initProductList="initProductList" />
  <ProductSwiperImageDialog :productId="id" v-model="productSwiperImageDialogVisible"></ProductSwiperImageDialog>
</template>

<script setup>

import {Search,Edit,Delete } from '@element-plus/icons-vue'
import { ref } from 'vue'
import  axios from '@/util/axios'
import {getServerUrl} from "@/config/sys";
import Dialog from './components/dialog'
// import ImageDialog from './components/imageDialog'
// import SwiperDialog from './components/swiperDialog'
// import ProductSwiperImageDialog from './components/productSwiperImageDialog'

import {ElMessageBox,ElMessage} from 'element-plus'



const queryForm=ref({
  query:'',
  pageNum:1,
  pageSize:10
})

const value2=ref(true)



const total=ref(0)

const id=ref(-1)

const swiperSort=ref(-1)

const tableData=ref([
  {
    num:'',
    examine:'',
    examine_type:'',
    text_isArrive:'',
    type_isArrive:'',
  }
])

const number=ref(1)

const yes_or_not=ref([

])

const range_department=ref([
  '选择预约挂号科室','急诊科','重症医学科','外一科（普外、肛肠等方向）','外二科（泌外、男科等方向）','骨伤一科（脊柱、脑外、创伤等方向）','骨伤二科（关节、胸外、创伤等方向）','妇产科','眼耳鼻喉科','手术室','口腔科','内一科（心病方向）','内二科（肝病、脾胃、肿瘤等方向）','内三科（脑病方向）','内五科（肺病、老年病等方向）','内六科（肾病、内分泌、风湿等方向）','针灸康复科','儿科','治未病科','门诊','体检科','药剂科','医学检验科','功能科','内镜室','高压氧','放射科','CT室','供应室','120中心','新院导诊','轮科'
])

const srcList=ref([
  'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
  'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',

  'https://seopic.699pic.com/photo/50179/1691.jpg_wh1200.jpg'
  // 'http://47.106.246.14:8081'+scope.row.imageUrl
])


const dialogValue=ref({})

const dialogTitle=ref('')

const initProductList=async()=>{
  console.log('xxx')
  const res=await axios.post("report-info/superAllList",queryForm.value);
  console.log(res)
  console.log('打印row')

  tableData.value=res.data.data.map.reportAllList;
  // console.log(tableData.value[5].imageUrl)
  // console.log(tableData.value[5].preDepartmentId)
  // tableData.value[5].preDepartmentId = tableData.value[5].preDepartmentId - 20
  // console.log(tableData.value[5].preDepartmentId)
  total.value=res.data.data.map.total;


  for (let i=0; i<res.data.data.map.reportAllList.length; i++){

    // if( tableData.value[i].num==null && number.value <= total.value){
    //   console.log(number)
    //   tableData.value[i].num=number.value
    //   console.log(tableData.value[i].num)
    //   number.value=number.value+1
    //   console.log(number)
    // }


    if( tableData.value[i].remark==1){
      yes_or_not[i]=true
      console.log(yes_or_not[i])
      tableData.value[i].remark=true
      console.log(tableData.value[i].remark)
      tableData.value[i].examine='已审核'
      tableData.value[i].examine_type='success'
      console.log(tableData.value[i].examine)
    }
    else{
      yes_or_not[i]=false
      console.log(yes_or_not[i])
      tableData.value[i].remark=false
      console.log(tableData.value[i].remark)
      tableData.value[i].examine='未审核'
      tableData.value[i].examine_type='danger'
      console.log(tableData.value[i].examine)
    }

    if(tableData.value[i].isArrive==1){
      tableData.value[i].text_isArrive='已入院'
      tableData.value[i].type_isArrive='success'
    }
    else{
      tableData.value[i].text_isArrive='未入院'
      tableData.value[i].type_isArrive='danger'
    }

  }
  console.log("打印yes_or_not")
  console.log(yes_or_not)
}

initProductList();

const dialogVisible=ref(false)

const imageDialogVisible=ref(false)

const swiperDialogVisible=ref(false)

const productSwiperImageDialogVisible=ref(false)


const handleSizeChange=(pageSize)=>{
  queryForm.value.pageNum=1;
  queryForm.value.pageSize=pageSize;
  initProductList();
}

const handleCurrentChange=(pageNum)=>{
  queryForm.value.pageNum=pageNum;
  initProductList();
}


const handleDialogValue = (row) => {
  if(row){
    dialogValue.value=JSON.parse(JSON.stringify(row));
    console.log("abc:"+dialogValue.value.reportId)
    dialogTitle.value="修改转诊单"
  }else{
    dialogValue.value={
      productIntroImgs:'',
      productParaImgs:'',
      type:{
        id:""
      }
    }
    dialogTitle.value="添加转诊单"
  }
  dialogVisible.value=true;
}

const handleDelete = (id) => {

  ElMessageBox.confirm(
    '您确定要删除这条记录吗?',
    '系统提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(async() => {
      console.log("id="+id)
      let res=await axios.del("report-info/removeById?reportId="+id);
      if(res.data.code==0){
        ElMessage({
          type: 'success',
          message: '删除成功！',
        });
        initProductList();
      }else{
        ElMessage({
          type: 'error',
          message: res.data.message,
        });
      }

    })
    .catch(() => {

    })
}


const typeFormatter = (row) => {
  return row.type.name
}



const hotChangeHandle = async (row) => {
  console.log(row);
  console.log("val="+row.id+","+row.hot);
  let res=await axios.get("admin/product/updateHot/"+row.id+"/state/"+row.hot);
  if(res.data.code==0){
    ElMessage({
      type: 'success',
      message: '执行成功！',
    });
  }else{
    ElMessage({
      type: 'error',
      message: res.data.msg,
    });
    initProductList();
  }
}


const hotSwiperChangeHandle = async (row) => {
  console.log("val="+row.id+","+row.hot);
  let res=await axios.get("admin/product/updateSwiper/"+row.id+"/state/"+row.swiper);
  if(res.data.code==0){
    ElMessage({
      type: 'success',
      message: '执行成功！',
    });
  }else{
    ElMessage({
      type: 'error',
      message: res.data.msg,
    });
    initProductList();
  }
}

const handleChangeImage = (row) => {
  id.value=row.id;
  imageDialogVisible.value=true;
}

const handleChangeSwiper = (row) => {
  id.value=row.id;
  swiperSort.value=row.swiperSort;
  swiperDialogVisible.value=true;
}

const handleChangeProductSwiperImage = (row) => {
  id.value=row.id;
  productSwiperImageDialogVisible.value=true;
}


</script>

<style lang="scss" scoped>

.header{
  padding-bottom: 16px;
  box-sizing: border-box;
}

.el-pagination{
  padding-top: 15px;
  box-sizing: border-box;
}


</style>